<template>
    <div>
        <ul class="field-list" v-if="procedure==1">
            <li v-for="(item,index) in templateField" :key="index">
              <div class="lable">{{item.formName}}</div>
              <div class="field">
                <Checkbox @on-change='onChange' v-model="e.status" v-for="(e,i) in item.authFieldList" :key="i">{{e.fieldValue == 'image' ? `${e.fieldName}（图片）`:e.fieldName}}</Checkbox>
              </div>
              
            </li>
          </ul>
       <Draggable v-if="procedure==2" v-model="DiyList" chosen-class="chosen" force-fallback="true" group="people" animation="1000" @start="onStart" @end="onEnd">
            <transition-group>
                <div class="dragitem" v-for="(item,index) in DiyList" :key="item.field"><span style="margin-right:5px">{{index+1}} </span> {{item.fieldName}}</div>
            </transition-group>
        </Draggable>
    </div>
</template>

<script>
import Draggable from "vuedraggable";
export default {
    props:['templateField','procedure' ],
    data () {
        return {
            drag: false,
 
            // 编辑对比
            templateFieldList:[],
            // 
            DiyList:[],
            imageList:[],
            
        }
    },
    methods:{
        onStart() {
            this.drag = true;
        },
        onEnd() {
            this.drag = false;
        },
        getDiyList(){
            this.templateFieldList = []
            this.templateField.map( item=> {
                item.authFieldList.forEach(e => {
                    if(e.status){
                        this.templateFieldList.push(e)
                    }
                })
            })
            
            // console.log(this.templateFieldList)
            this.templateFieldList.map( e=> {
                if(this.DiyList.indexOf(e) == -1){
                    this.DiyList.push(e)
                }
            })
            // let arr = this.DiyList.filter(item => {
            //     this.templateFieldList.indexOf(item) != -1
            // })
            let arr = []
            this.DiyList.map(e => {
                if(this.templateFieldList.indexOf(e) != -1){
                    arr.push(e)
                }
            })
            this.DiyList = arr
            
        },
        // 重置
        reset(){
            this.templateFieldList = [],
            this.DiyList= []  
        }
        
    },
    components:{
        Draggable,
    },
    watch:{
        templateField:{
            deep:true,
            handler(newVal,oldVal){
                this.imageList = []
                console.log(newVal)
                newVal[1].authFieldList.map( e => {
                    if(e.fieldValue == 'image' && e.status){
                        this.imageList.push(e)
                    }
                })
                this.$emit('getImageList',this.imageList)
            }
        },
        DiyList(val){
            this.$emit('getval',val)
        }
    }
}
</script>
 
<style lang = "less" scoped>
.field-list{
  li{
    padding: 5px 0 5px 30px;
    display: flex;
    &:nth-of-type(even){
      background-color: #F8F8F9;
    }
    
      .lable{
        font-size: 16px;
        font-weight: 700;
        width: 140px;
        text-align: right;
        padding-right: 40px;
        box-sizing: border-box;
      }
      .field{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .ivu-checkbox-wrapper{
            width: 230px;
            margin: 3px 0;
        }
      }
  }
}
.dragitem{
    margin-bottom: 5px;
}
.dragitem:hover {
    background-color: #f1f1f1;
    cursor: move;
}
</style>