<template>
  <Form class="form">
    <div v-for="(item, index) in mainList" :key="index">
      <div v-if="item.show">
        <div
          v-if="
            item.type != 'Radio' &&
            item.type != 'Checkbox' &&
            item.type != 'File' &&
            item.type != 'Image'
          "
          style="margin-bottom: 4px"
        >
          <span class="itemRequired" v-if="item.required"></span
          >{{ index + 1 }}.{{ item.title }}
        </div>
        <div v-else style="margin-bottom: 4px">
          <span class="itemRequired" v-if="item.required"></span
          >{{ index + 1 }}.{{ item.title
          }}<span v-if="item.placeholder" style="color: #aaa"
            >({{ item.placeholder }})</span
          >
        </div>
        <FormItem>
          <!-- 单选 -->
          <RadioGroup
            v-if="item.type == 'Radio'"
            :vertical="item.vertical"
            class="radio"
            v-model="item.answer"
            @on-change="(val) => linkAgeFn(val, item)"
          >
            <Radio
              :label="items.label"
              v-for="(items, indexs) in item.optionList"
              :key="indexs"
            >
            </Radio>
          </RadioGroup>
          <!-- 多选框 -->
          <CheckboxGroup
            v-model="item.answer"
            @on-change="(val) => linkAgeFn(val, item)"
            v-if="item.type == 'Checkbox'"
          >
            <Checkbox
              :style="{
                display: item.vertical ? 'block' : 'inline-block',
              }"
              :label="items.label"
              v-for="(items, indexs) in item.optionList"
              :key="indexs"
              >{{ items.label }}</Checkbox
            >
          </CheckboxGroup>
          <!-- 下拉选择器 -->
          <Select
            v-model="item.answer"
            v-if="item.type == 'Select'"
            @on-change="(val) => linkAgeFn(val, item)"
            :multiple="item.multiple"
            :filterable="item.search"
            :placeholder="item.placeholder"
          >
            <Option
              v-for="(items, indexs) in item.optionList"
              :value="items.label"
              :key="indexs"
              >{{ items.label }}</Option
            >
          </Select>
          <!-- 单行输入框 -->
          <Input
            v-model="item.answer"
            :placeholder="item.placeholder"
            v-if="item.type == 'Input'"
          />
          <!-- 多行输入框 -->
          <Input
            v-model="item.answer"
            :placeholder="item.placeholder"
            type="textarea"
            v-if="item.type == 'Textarea'"
          ></Input>
          <!-- 数字 -->
          <InputNumber
            :formatter="
              item.unit ? (val) => `${val}${item.unit}` : (val) => val
            "
            :parser="
              item.unit ? (val) => val.replace(item.unit, '') : (val) => val
            "
            :max="item.max"
            :min="item.min"
            v-model="item.answer"
            :placeholder="item.placeholder"
            style="width: 200px"
            :step="item.step"
            :precision="item.precision"
            v-if="item.type == 'InputNumber'"
          ></InputNumber>
          <!-- 日期 -->
          <DatePicker
            type="date"
            :placeholder="item.placeholder"
            v-model="item.answer"
            v-if="item.type == 'DatePicker'"
          ></DatePicker>
          <!-- 时间 -->
          <TimePicker
            type="time"
            :placeholder="item.placeholder"
            v-model="item.answer"
            v-if="item.type == 'TimePicker'"
          ></TimePicker>
          <!-- 附件 -->
          <Upload
            :multiple="item.multiple"
            type="drag"
            :action="action"
            :default-file-list="item.uploadList"
            :before-upload="beforeUpload"
            :on-success="successUpload"
            :on-remove="removeFile"
            :data="uploadData"
            v-if="item.type == 'File'"
          >
            <div style="padding: 20px 0" @click="uploadIndex(index)">
              <Icon
                type="ios-cloud-upload"
                size="52"
                style="color: #3399ff"
              ></Icon>
              <p>点击或推拽上传文件</p>
            </div>
          </Upload>
          <!-- 图片 -->
          <div v-if="item.type == 'Image'">
            <div
              class="demo-upload-list"
              v-for="(subItem, subIndex) in item.uploadList"
              :key="subIndex"
            >
              <template v-if="subItem.status === 'finished'">
                <img
                  :src="subItem.url"
                  alt=""
                  style="width: 200px; margin-right: 10px"
                  v-if="subItem.url && item.type == 'Image'"
                />
                <div class="demo-upload-list-cover">
                  <Icon
                    type="ios-trash-outline"
                    @click.native="handleRemove(index, subIndex)"
                  ></Icon>
                </div>
              </template>
              <template v-else>
                <Progress
                  v-if="subItem.showProgress"
                  :percent="subItem.percentage"
                  hide-info
                ></Progress>
              </template>
            </div>
          </div>
          <Upload
            ref="upload"
            v-if="item.type == 'Image'"
            :multiple="item.multiple"
            :action="action"
            :on-progress="fileProgress"
            :show-upload-list="false"
            :data="uploadData"
            accept="image/*"
            :on-success="successUpload"
            :before-upload="beforeUpload"
            style="display: inline-block; width: 58px"
          >
            <div
              style="
                width: 58px;
                height: 58px;
                line-height: 58px;
                text-align: center;
                border: 1px dashed #dcdee2;
                border-radius: 4px;
                cusor: pointer;
              "
              @click="uploadIndex(index)"
            >
              <Icon type="ios-camera" size="20"></Icon>
            </div>
          </Upload>
          <!-- 视频 -->
          <div v-if="item.type == 'Video'">
            <div
              class="demo-upload-list demo-upload-list-video"
              v-for="(subItem, subIndex) in item.uploadList"
              :key="subIndex"
            >
              <template v-if="subItem.status === 'finished'">
                <video
                  :src="subItem.url"
                  controls="controls"
                  width="400px"
                  height="200px"
                  v-if="subItem.url && item.type == 'Video'"
                />
                <div class="demo-upload-list-cover-video">
                  <Icon
                    type="md-close-circle"
                    @click.native="handleRemove(index, subIndex)"
                  ></Icon>
                </div>
              </template>
              <template v-else>
                <Progress
                  v-if="subItem.showProgress"
                  :percent="subItem.percentage"
                  hide-info
                ></Progress>
              </template>
            </div>
          </div>
          <Upload
            ref="upload"
            v-if="item.type == 'Video'"
            :multiple="item.multiple"
            :action="action"
            :on-progress="fileProgress"
            :show-upload-list="false"
            :data="uploadData"
            accept="video/*"
            :on-success="successUpload"
            :before-upload="beforeUpload"
            style="display: inline-block; width: 58px"
          >
              <div @click="uploadIndex(index)" style="width: 80px; height: 80px; line-height: 80px;border: 1px dashed rgb(220, 222, 226);border-radius: 4px;" v-if="item.type == 'Video'">
                        <Icon
                          type="md-add"
                          size="80"
                          style="color: #bbb;"
                        ></Icon>
                      </div>
          </Upload>
          <!-- 级联选择器 -->
          <Cascader
            :data="cascaderData"
            v-if="item.type == 'Cascader'"
            filterable
            v-model="item.answer"
            :placeholder="item.placeholder"
          ></Cascader>

          <!-- 选择地区组件 -->
          <Input
            v-model="item.answer"
            :placeholder="item.placeholder"
            v-if="item.type == 'ZoneSelect'"
          />
        </FormItem>
      </div>
    </div>
  </Form>
</template>

<script>
export default {
  props: [
    "previewFormData",
    "infoId",
    "onlyPreview",
    "informationId",
    "activeMainList",
  ],
  data() {
    return {
      mainList: [],
      action: "",
      uploadData: {},
      index: "",
      actionPath: "",
      cascaderData: [],
    };
  },
  methods: {
    // 联动控制————单选/多选/下拉
    linkAgeFn(val, item) {
      if (item.linkAgeArr && item.linkAgeArr.length > 0) {
        item.optionList.map((subItem) => {
          if (
            typeof val == "object"
              ? val.indexOf(subItem.label) != -1
              : val == subItem.label
          ) {
            //此时找到选项id,通过选项id匹配联动linkAgeArr中的联动设置
            item.linkAgeArr.map((k) => {
              if (k.value == subItem.id) {
                this.mainList.map((i) => {
                  if (i.id == k.title) {
                    if (k.result == "hidden") {
                      i.show = false;
                    } else if (k.result == "display") {
                      i.show = true;
                    } else if (k.result == "notnull") {
                      i.show = true;
                      // lastRequired是为了保存用户设置的必填与否
                      i.lastRequired =
                        i.lastRequired == undefined
                          ? i.required
                          : i.lastRequired;
                      i.required = true;
                    }
                  }
                });
              }
            });
          } else {
            //否则，不是选中的选项恢复原来的状态
            item.linkAgeArr.map((k) => {
              if (k.value == subItem.id) {
                this.mainList.map((i) => {
                  if (i.id == k.title) {
                    i.show = true;
                    i.required =
                      i.lastRequired != undefined ? i.lastRequired : i.required;
                  }
                });
              }
            });
          }
        });
      }
    },
    uploadIndex(index) {
      this.index = index;
    },
    // 采集信息保存
    save() {
      //必填
      for (let k in this.mainList) {
        if (
          this.mainList[k].required &&
          this.mainList[k].show &&
          (this.mainList[k].answer == "" ||
            this.mainList[k].answer == null ||
            this.mainList[k].answer == undefined)
        ) {
          this.$Message.error({
            background: true,
            content:
              "请填写第" + (Number(k) + 1) + "题:" + this.mainList[k].title,
          });
          return;
        }
      }

      //单行文本框校验类型
      for (let k in this.mainList) {
        if (
          this.mainList[k].show &&
          this.mainList[k].ruleType == "1" &&
          this.mainList[k].type == "Input" &&
          this.mainList[k].answer != ""
        ) {
          //校验身份证
          let regFormat = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //正确身份证
          if (!regFormat.test(this.mainList[k].answer)) {
            this.$Message.error({
              background: true,
              content: "第" + (Number(k) + 1) + "题:请输入有效的身份证号",
            });
            return;
          }
        } else if (
          this.mainList[k].show &&
          this.mainList[k].ruleType == "2" &&
          this.mainList[k].type == "Input" &&
          this.mainList[k].answer != ""
        ) {
          //手机号
          let regFormat = /^((0\d{2,3}-\d{7,8})|(^1[3-9]\d{9}))$/;
          if (!regFormat.test(this.mainList[k].answer)) {
            this.$Message.error({
              background: true,
              content: "第" + (Number(k) + 1) + "题:请输入正确的手机号码",
            });
            return;
          }
        } else if (
          this.mainList[k].show &&
          this.mainList[k].ruleType == "3" &&
          this.mainList[k].type == "Input" &&
          this.mainList[k].answer != ""
        ) {
          //邮箱
          let regFormat = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
          if (!regFormat.test(this.mainList[k].answer)) {
            this.$Message.error({
              background: true,
              content: "第" + (Number(k) + 1) + "题:请输入正确的邮箱",
            });
            return;
          }
        } else if (
          this.mainList[k].show &&
          this.mainList[k].ruleType == "4" &&
          this.mainList[k].type == "Input" &&
          this.mainList[k].answer != ""
        ) {
          //数字
          let regFormat = /^[0-9]+$/;
          if (!regFormat.test(this.mainList[k].answer)) {
            this.$Message.error({
              background: true,
              content: "第" + (Number(k) + 1) + "题:请输入数字",
            });
            return;
          }
        } else if (
          this.mainList[k].show &&
          this.mainList[k].ruleType == "5" &&
          this.mainList[k].type == "Input" &&
          this.mainList[k].answer != ""
        ) {
          //字母
          let regFormat = /^[A-Za-z]+$/;
          if (!regFormat.test(this.mainList[k].answer)) {
            this.$Message.error({
              background: true,
              content: "第" + (Number(k) + 1) + "题:请输入英文字母",
            });
            return;
          }
        }
      }

      let parameterMap = {};
      this.mainList.map((item) => {
        if (item.show) {
          let answer = [];
          if (typeof item.answer != "object") {
            answer = [String(item.answer)];
          } else if (item.type == "DatePicker") {
            answer = [String(item.answer)];
          } else {
            answer = item.answer;
          }
          parameterMap[item.fieldCode] = answer;
        }
      });
      this.$post(
        "/datamsg/api/pc/dynamicform/saveFormData",
        {
          busiId: this.$route.query.id || this.informationId,
          creator: parent.vue.loginInfo.userinfo.realName,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          formId: this.previewFormData.id,
          dataInfoId: "",
          parameterMap: parameterMap,
          dataInfoId: this.infoId,
          projectCode: parent.vue.loginInfo.userinfo.orgCode,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
        },
        { "Content-Type": "application/json" }
      )
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            this.$emit("saveSuccess");
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "保存失败，请重试",
          });
        });
    },
    // 从活动修改传进来的表单数据
    activityDataFn(data) {
      if (data.interactionFormVos) {
        //根据响应数据，组织mainList
        this.mainList = data.interactionFormVos.map((item) => {
          let temp = {}; //mainList的key和value
          let rightCom = ""; //右侧组件
          let type = "";
          switch (item.content) {
            case "radio":case "sex":case"political":
              rightCom = "RadioCom";
              type = "Radio";
              break;
            case "checkbox":
              rightCom = "CheckboxCom";
              type = "Checkbox";
              break;
            case "selected":
              rightCom = "SelectCom";
              type = "Select";
              break;
            case "text": case "userName":case "mobile":case "idNum":case "addr":case"marital":case"postalCode":case"workUnits":case"national":case"vaccines":case"brand":case"result":case"ZoneSelect":
              rightCom = "InputCom";
              type = "Input";
              break;
            case "textarea":
              rightCom = "TextareaCom";
              type = "Textarea";
              break;
            case "number":
              rightCom = "NumberCom";
              type = "InputNumber";
              break;
            case "time":case"brithday":case"testing":
              rightCom = "TimePickerCom";
              type = "TimePicker";
              break;
            case "date":
              rightCom = "DatePickerCom";
              type = "DatePicker";
              break;
            case "file":
              rightCom = "FileCom";
              type = "File";
              break;
            case "image":
              rightCom = "ImageCom";
              type = "Image";
              break;
            case "video":
              rightCom = "VideoCom";
              type = "Video";
              break;
            case "cascader":
              rightCom = "CascaderCom";
              type = "Cascader";
              break;    
           case 'ZoneSelectCom':
                  rightCom = 'ZoneSelectCom';
                  type = "ZoneSelect"
                  break
          }
          if (type == "InputNumber") {
            item.answer = Number(item.answer);
          }
          let keyValue = item.fieldParamVos.map((items) => {
            // 回显默认当前时间
            if (
              (items.paramCode == "nowTime" || items.paramCode == "nowDate") &&
              items.paramValue == "true"
            ) {
              item.answer = new Date();
            }
            // 下拉框多选
            if (
              type == "Select" &&
              items.paramCode == "multiple" &&
              items.paramValue === "true" &&
              item.answer
            ) {
              item.answer = item.answer.split(",");
            } else if (
              type == "Select" &&
              items.paramCode == "multiple" &&
              items.paramValue === "true" &&
              !item.answer
            ) {
              item.answer = [];
            }

            // 把字符串形式的boolean转Boolean
            if (
              items.paramCode == "vertical" ||
              items.paramCode == "required" ||
              items.paramCode == "nowTime" ||
              items.paramCode == "nowDate" ||
              items.paramCode == "search" ||
              items.paramCode == "multiple"
            ) {
              items.paramValue =
                items.paramValue == "true"
                  ? true
                  : items.paramValue == "false"
                  ? false
                  : "";
            }
            if (
              items.paramCode == "min" ||
              items.paramCode == "max" ||
              items.paramCode == "step" ||
              items.paramCode == "precision"
            ) {
              items.paramValue = Number(items.paramValue);
            }
            if (type == "Input" && items.paramCode == "ruleType") {
              //input校验类型
              item.authentication = items.paramValue;
            }

            // 数字
            if (
              type == "InputNumber" &&
              (item.answer == "" ||
                item.answer == undefined ||
                item.answer == null) &&
              items.paramCode == "min" &&
              items.paramValue
            ) {
              item.answer = items.paramValue;
            }
            temp[items.paramCode] = items.paramValue;
            return {
              value: items.paramCode,
              label: items.paramName,
            };
          });

          let answer = "";
          if (
            type == "Checkbox" &&
            item.answer &&
            typeof item.answer == "string"
          ) {
            item.answer = item.answer.split(",");
          } else if (type == "Checkbox" && !item.answer) {
            //复选框为空时
            item.answer = [];
          }

          //图片和文件
          if (
            (type == "File" || type == "Image" || type == "Video") &&
            item.answer &&
            typeof item.answer == "string"
          ) {
            item.answer = item.answer.split(",");
            item.uploadList = item.answer.map((key) => {
              return {
                status: "finished",
                url: key,
                name: key.substring(key.lastIndexOf("/") + 9),
              };
            });
          } else if (
            (type == "Checkbox" ||
              type == "File" ||
              type == "Image" ||
              type == "Video" ||
              type == "Cascader") &&
            !item.answer
          ) {
            //复选框为空时
            item.answer = [];
          }

          return {
            id: item.fieldId,
            type: type,
            ...temp,
            ruleType: item.authentication,
            answer: item.answer,
            fieldCode: item.fieldCode,
            label: item.content,
            uploadList: item.uploadList || [],
            keyValue: keyValue,
            label: item.content,
            rightCom: rightCom,
            show: true, //控制联动
            optionList: item.list
              ? item.list.map((items) => {
                  return {
                    value: items.sequence,
                    label: items.title,
                    person: items.person,
                    id: items.field,
                  };
                })
              : [],
            linkAgeArr: (() => {
              //组织联动设置
              let arr = [];
              for (let k in item.list) {
                if (item.list[k].linkageFieldId) {
                  let linkageFieldId = item.list[k].linkageFieldId.split(",");
                  // let linkageAction = item.list[k].linkageAction.split(",");
                  if (linkageFieldId.length > 1) {
                    linkageFieldId.map((subItem, subIndex) => {
                      arr.push({
                        value: item.list[k].field,
                        title: linkageFieldId[subIndex],
                        result: item.list[k].linkageAction,
                      });
                    });
                  } else {
                    arr.push({
                      value: item.list[k].field,
                      title: item.list[k].linkageFieldId,
                      result: item.list[k].linkageAction,
                    });
                  }
                }
              }
              return arr;
            })(),
          };
        });
      }
    },
    fileProgress(event, file, fileList) {
      this.mainList[this.index].uploadList = fileList;
      event.target.onprogress = (event) => {
        let uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        ); // 保留两位小数，具体根据自己需求做更改
        // 手动设置显示上传进度条 以及上传百分比
        this.mainList[this.index].uploadList.showProgress = true;
        this.mainList[this.index].uploadList.percentage = uploadPercent;
      };
    },
    // 删除文件
    removeFile(file, fileList) {
      this.mainList.map((item, index) => {
        if (item.type == "File") {
          item.uploadList.map((subItem, subIndex) => {
            if (file.uid == subItem.uid) {
              item.answer.splice(subIndex, 1);
              item.uploadList.splice(subIndex, 1);
              return;
            }
          });
        }
      });
    },
    // 删除图片
    handleRemove(index, subIndex) {
      this.mainList[index].uploadList.splice(subIndex, 1);
      this.mainList[index].answer.splice(subIndex, 1);
    },
    beforeUpload(file) {
      console.log(file)
      if (this.actionPath) {
        this.$Message.warning({
          background: true,
          content: "已有文件正在上传中，请等前一个文件上传完毕后再上传",
        });
        return false;
      }
      if (file.size / 1024 / 1024 > this.mainList[this.index].limit) {
        this.$Notice.error({
          title: "上传失败",
          desc:
            "文件 " +
            file.name +
            " 大小已超出限制，请控制在" +
            this.mainList[this.index].limit +
            "M以内",
        });
        return false;
      }
      if (
        this.mainList[this.index].multiple == false &&
        this.mainList[this.index].answer.length == 1
      ) {
        this.$Notice.error({
          title: "上传失败",
          desc: "文件上传以达到上限，最多只能上传一个文件",
        });
        return false;
      }
      if (
        this.mainList[this.index].max == this.mainList[this.index].answer.length
      ) {
        this.$Notice.error({
          title: "上传失败",
          desc:
            "文件上传以达到上限，请控制在" +
            this.mainList[this.index].max +
            "个以内",
        });
        return false;
      }
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = this.$core.randomString(8);
          this.uploadData = {
            name: now + file.name,
            host: res.data.host,
            key: res.data.key + now + file.name,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.action = res.data.host;
          this.actionPath = res.data.host + res.data.key + now + file.name;
        } else {
          this.$Message.error({
            background: true,
            content: "功能异常，请联系管理员处理",
          });
        }
      });
    },
    successUpload(res, file, fileList) {
      if (this.mainList[this.index].type == "File") {
        this.mainList[this.index].uploadList = fileList;
      }
      this.mainList[this.index].answer.push(this.actionPath);
      if (this.mainList[this.index].type == "Image" || this.mainList[this.index].type == "Video") {
        //此时是上传图片或视频
        this.mainList[this.index].uploadList[
          this.mainList[this.index].uploadList.length - 1
        ].url = this.actionPath;
      }
      console.log(this.mainList)
      this.actionPath = "";
    },
  },
  created() {
    if (this.onlyPreview) {
      //这是表单编辑的预览
      this.mainList = this.activeMainList.map((item) => {
        return {
          ...item,
          uploadList: [],
          show: true, //控制联动
          answer: (() => {
            let answer = "";
            switch (item.type) {
              case "Radio":
                answer = "";
                break;
              case "Checkbox":
                answer = [];
                break;
              case "Select":
                if (item.multiple) {
                  answer = [];
                } else {
                  answer = "";
                }
                break;
              case "Input":
                answer = "";
                break;
              case "Textarea":
                answer = "";
                break;
              case "InputNumber":
                answer = item.min;
                break;
              case "TimePicker":
                if (item.nowTime) {
                  answer = new Date();
                } else {
                  answer = null;
                }
                break;
              case "DatePicker":
                if (item.nowDate) {
                  answer = new Date();
                } else {
                  answer = null;
                }
                break;
              case "File":
                answer = [];
                break;
              case "Image":
                answer = [];
                break;
              case "Video":
                answer = [];
                break;
              case "Cascader":
                if (this.cascaderData.length == 0) {
                  this.cascaderData = [
                    {
                      value: "街巷",
                      label: "街巷",
                      children: [
                        {
                          value: "小区",
                          label: "小区",
                          children: [
                            {
                              value: "楼栋",
                              label: "楼栋",
                              children: [
                                {
                                  value: "房号",
                                  label: "房号",
                                },
                              ],
                            },
                            {
                              value: "房号",
                              label: "房号",
                            },
                          ],
                        },
                        {
                          value: "楼栋",
                          label: "楼栋",
                          children: [
                            {
                              value: "房号",
                              label: "房号",
                            },
                          ],
                        },
                        {
                          value: "门牌号",
                          label: "门牌号",
                        },
                      ],
                    },
                  ];
                }

                answer = [];
                break;
              case "ZoneSelect":
                answer = "";
                break;
            }
            

            return answer;
          })(),
        };
      });
    } else {
      //这是活动查看
      this.activityDataFn(this.previewFormData);
    }
  },
};
</script>
    
<style scoped lang='less'>
.form {
  margin: 0 20px 20px;
  .ivu-form-item {
    margin-bottom: 20px;
  }
  // 必填
  .itemRequired {
    &::before {
      content: "*";
      display: inline-block;
      margin-right: 4px;
      line-height: 1;
      font-family: SimSun;
      font-size: 14px;
      color: #ed4014;
    }
  }
}
.demo-upload-list {
  display: inline-block;
  width: 200px;
  height: 200px;
  text-align: center;
  line-height: 200px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
  &.demo-upload-list-video {
    width: 400px;
    height: 200px;
    overflow: visible;
    margin-right: 20px;
     /deep/.ivu-progress {
      overflow: hidden !important;
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  &:hover .demo-upload-list-cover {
    display: block;
  }
  .demo-upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
  .demo-upload-list-cover-video {
    position: absolute;
    right: -3px;
    top: -8px;
    width: 28px;
    height: 28px;
    .ivu-icon-md-close-circle {
      background-color: #fff;
      position: absolute;
      font-size: 22px;
      box-shadow:  0 0 4px 0 rgba(0,0,0,0.3);
      border-radius: 50%;
    }
  }
}
</style>