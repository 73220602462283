<template>
    <div style="display: inline-flex;align-items: center;vertical-align: middle">
        <div v-if="groupInfo" style="display: inline-flex;align-items: center;line-height: 1;">
            <span>当前所绑定沟通组：</span>
            <p style="display: inline-block;color:#3399ff;cursor: pointer;" @click="Route.jump('/groupindex')">{{ groupInfo.groupName }}</p>
            <Button size="small" @click="onAddGroupMember" icon="ios-add" style="margin:0 0 0 10px">勾选添加群员</Button>
        </div>
        <Button v-else type="info" style="margin:0" @click="onDisplayCreateGroup">勾选创建沟通组</Button>

        <!-- 建群表单 -->
        <LiefengModal title="建群" width="400px" :value="createGroupStatus" @input="createGroupStatus = $event">
            <template v-slot:contentarea>
                <Form style="margin: 0 10px">
                    <FormItem label="群名称" style="margin-bottom:10px">
                        <Input v-model.trim="createGroupForm.groupName" placeholder="请输入群名称" />
                    </FormItem>
                    <FormItem label="群类型" style="margin-bottom:10px">
                        <Select v-model="createGroupForm.groupType" placeholder="请选择群类型" transfer>
                            <Option v-for="item in groupTypes" :key="item.dictKey" :value="item.dictKey">{{ item.dictValue }}</Option>
                        </Select>
                    </FormItem>
                </Form>
                <Button type="info" style="width: calc(100% - 20px);margin: 10px" @click="onSubmitCreateGroup">提交</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import Route from "../../communitymobilization/entity/Route"

export default {
    components: {
        LiefengModal,
    },

    data() {
        return {
            Route: Route,

            createGroupStatus: false,
            createGroupForm: {
                groupName: "",
                groupType: "",
            },
            // 沟通组类型
            groupTypes: null,
            // 沟通组信息
            groupInfo: null,
        }
    },

    props: {
        informationId: String,
        userList: {
            type: Array,
            default: () => [],
        },
    },

    mounted() {
        this.getGroupInfo().then(res => {
            this.groupInfo = res
        })
    },

    methods: {
        /**
         * 显示建群表单页
         */
        async onDisplayCreateGroup() {
            if (!this.userList?.length) {
                return this.$Message.warning({
                    background: true,
                    content: "请先勾选需要建群的用户",
                })
            }

            if (!this.groupTypes) {
                await this.getGroupTypes()
            }

            this.createGroupStatus = true
        },

        /**
         * 获取沟通组信息
         */
        getGroupInfo() {
            return new Promise(resolve => {
                this.$get(`/info/api/pc/information/v2/selectByGroupId?informationId=${this.informationId}`).then(res => {
                    if (res.code === "200" && res.data?.status === "1") {
                        resolve(res.data)
                    }
                })
            })
        },

        /**
         * 获取沟通组类型
         */
        async getGroupTypes() {
            await this.$get("/dictlist/api/lfcommon/pc/dict/selectDictDataList", {
                dictType: "CHAT_GROUP_TYPE",
                appCode: "",
            }).then(res => {
                this.groupTypes = res?.dataList?.filter(v => v.extendValue !== "readonly")
            })
        },

        /**
         * 提交建群表单
         */
        onSubmitCreateGroup() {
            const fd = this.createGroupForm

            if (!fd.groupName) {
                return this.$Message.warning({
                    background: true,
                    content: "请填写群名称",
                })
            }

            if (!fd.groupType) {
                return this.$Message.warning({
                    background: true,
                    content: "请选择群类型",
                })
            }

            this.$post(
                "/info/api/pc/information/v2/createInfoGroup",
                {
                    ...fd,
                    informationId: this.informationId,
                    orgCode: parent.vue.loginInfo.userinfo.communityCode,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalIdList: this.userList.map(item => item.custGlobalId),
                },
                {
                    "Content-Type": "application/json",
                }
            ).then(res => {
                if (res.code !== "200") {
                    return this.$Message.error({
                        background: true,
                        content: res.desc || "创建失败，请稍后重试！",
                    })
                }

                this.$Message.success({
                    background: true,
                    content: "创建成功！",
                })

                this.createGroupStatus = false

                this.getGroupInfo().then(res => {
                    this.groupInfo = res
                })
            })
        },

        /**
         * 添加群员
         */
        onAddGroupMember() {
            if (!this.userList?.length) {
                return this.$Message.warning({
                    background: true,
                    content: "请先勾选添加的用户",
                })
            }

            this.$post(
                "/info/api/pc/information/v2/createInfoGroup",
                {
                    informationId: this.informationId,
                    orgCode: parent.vue.loginInfo.userinfo.communityCode,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    custGlobalIdList: this.userList.map(item => item.custGlobalId),
                },
                {
                    "Content-Type": "application/json",
                }
            ).then(res => {
                if (res.code !== "200") {
                    return this.$Message.error({
                        background: true,
                        content: res.desc || "添加失败，请稍后重试！",
                    })
                }

                this.$Message.success({
                    background: true,
                    content: "添加成功！",
                })
            })
        },
    },
}
</script>
